import React, {useEffect, useRef, useState} from "react";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Toast} from "primereact/toast";
import ResellerOrderService from "./httpService";
import {handleError} from "../../service/HandleErrorService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import moment from "moment/moment";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import {Dialog} from "primereact/dialog";

const ResellerOrderView = () => {

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 0,
    activePage: 0,
  });
  const toast = useRef(null);
  const [searchRequest, setSearchRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [completeResellerOrders, setCompleteResellerOrders] = useState([]);
  const resellerOrderService = new ResellerOrderService();
  const [orderTypes, setOrderTypes] = useState([]);
  const [orderDetailDialog, setOrderDetailDialog] = useState(false);
  const [isTtg, setIsTttg] = useState(false);
  const [isDisty, setIsDisty] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [exportExcelDisabled, setExportExcelDisabled] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});

  const [excelData, setExcelData] = useState([]);
  const [excelDetailData, setExcelDetailData] = useState([]);


  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem('user')).user.roles;
    if (roles.includes("TTG_ADMIN")) {
      setIsTttg(true);
    } else if (roles.includes("RESELLER_ADMIN")) {
      setIsReseller(true)
    } else if (roles.includes("DISTY_ADMIN")) {
      setIsDisty(true);
    }
    getAll({activePage: paginationConfig.activePage, itemsPerPage: paginationConfig.itemsPerPage})
    getAllOrderTypes();
  }, []);
  useEffect(() => {
    getAll({activePage: paginationConfig.activePage, itemsPerPage: paginationConfig.itemsPerPage})

  }, [searchRequest])

  const getAllOrderTypes = () => {
    resellerOrderService.getOrderType().then(res => {
      setOrderTypes(res)
    }).catch(err => handleError(err, toast))
  }
  const getAll = (pageState) => {
    setLoading(true);
    let _searchRequest = {...searchRequest};


    var sort = "";
    if (_searchRequest.sortField != null && _searchRequest.sortOrder != null) {
      sort += _searchRequest.sortField;
      if (_searchRequest.sortOrder === 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort = "createDateTime,asc";
    }
    resellerOrderService.getAllCompletedResellerProductOrder(_searchRequest, pageState.activePage, pageState.itemsPerPage, sort).then(res => {
      setCompleteResellerOrders(res?.content)
      pageState.first = pageState.itemsPerPage * pageState.activePage;
      pageState.totalRecords = res.totalElements;
      setPaginationConfig({
        ...pageState,
      });
      setLoading(false)

    }).catch(err => handleError(err, toast))

  }
  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };

  const onSort = (e) => {
    console.log(e)
    let _lazyParams = {...searchRequest, ...e};
    setSearchRequest(_lazyParams);
  };

  const actions = (rowData) => {
    return (
      <div>
        <Button label="Detay" className="p-button-success" onClick={e => {
          setOrderDetailDialog(true)
          resellerOrderService.getAllCompletedResellerProductOrderById(rowData?.id).then(res => {

            setCurrentOrder(res);


          })

        }
        }/>
      </div>
    )
  }

  const renderDistyOrderNoFilter = () => {
    return (
      <InputText value={searchRequest?.distyOrderNo}
                 onChange={e => {
                   let _searchRequest = {...searchRequest};
                   _searchRequest.distyOrderNo = e.target.value;
                   setSearchRequest(_searchRequest)

                 }} className="p-column-filter"/>
    )
  }
  const distyOrderNoFilter = renderDistyOrderNoFilter();

  const renderHasOrderName = () => {
    return (
      <InputText value={searchRequest?.nameAndSurname}
                 onChange={e => {
                   let _searchRequest = {...searchRequest};
                   _searchRequest.nameAndSurname = e.target.value;
                   setSearchRequest(_searchRequest)

                 }} className="p-column-filter"/>
    )
  }
  const hasOrderName = renderHasOrderName();

  const renderHasOrderGsmNo = () => {
    return (
      <InputText value={searchRequest?.gsmNo}
                 onChange={e => {
                   let _searchRequest = {...searchRequest};
                   _searchRequest.gsmNo = e.target.value;
                   setSearchRequest(_searchRequest)

                 }} className="p-column-filter"/>
    )
  }
  const hasOrderGsmNo = renderHasOrderGsmNo();


  const renderOrderStatusFilter = () => {
    return (
      <Dropdown placeholder="Onay Durumu" options={orderTypes} value={searchRequest?.orderStatus}
                optionValue="key" optionLabel="value" onChange={e => {
        let _searchRequest = {...searchRequest};
        _searchRequest.orderStatus = e.target.value
        setSearchRequest(_searchRequest)
      }}
                showClear/>
    )
  }
  const orderStatusFilter = renderOrderStatusFilter();


  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const exportExcel = () => {
    setExportExcelDisabled(true)
    resellerOrderService.getEagerExcelData(searchRequest).then(res => {


      import('xlsx').then(xlsx => {
        var excelList = [];
        res?.forEach(a => {
          console.log(a)
          excelList.push({

            "Sipariş No": a?.distyOrderNo,
            "Sipariş Sahibi": a?.ownUser?.name + " " + a?.ownUser?.surname,
            "Sipariş Sahibi Tel": a?.ownUser?.gsmNo,
            "Onay Durumu": a?.confirmationResellerProductOrder?.orderStatus === "WAITING" ? "Onay Bekliyor" :
              a?.confirmationResellerProductOrder?.orderStatus === "APPROVED" ? "Onaylandı" :
                a?.confirmationResellerProductOrder?.orderStatus === "REJECTED" ? "Reddedildi" :
                  "İptal Edildi",
            "Sipariş Tarihi": moment(a?.requestDate).format("DD-MM-yyyy HH:mm:ss"),
            "Toplam Tutar": a?.totalPrice,
            "Toplam Adet": a?.totalQuantity,
           /* "Onaylanan Toplam Tutar": a?.confirmationResellerProductOrder?.totalPrice ? a?.confirmationResellerProductOrder?.totalPrice : "İnceleme Aşamasında",
            "Onaylanan Toplam Adet": a?.confirmationResellerProductOrder?.totalQuantity ? a?.confirmationResellerProductOrder?.totalQuantity : "İnceleme Aşamasında"*/


          })


        });
        const worksheet = xlsx.utils.json_to_sheet(excelList);
        var wscols = [
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
        ];
        worksheet["!cols"] = wscols;
        const workbook = {
          Sheets: {'Bayi Sipariş Excel Dökümanı': worksheet},
          SheetNames: ['Bayi Sipariş Excel Dökümanı']
        };
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'Bayi Sipariş Excel Dökümanı');
      })
    }).catch(err => {
      setExportExcelDisabled(false)

    }).finally(() => setExportExcelDisabled(false));


  }
  const exportExcelDetail = () => {
    setExportExcelDisabled(true)
    resellerOrderService.getEagerExcelDataDetail(searchRequest).then(res => {


      import('xlsx').then(xlsx => {
        var excelList = [];
        res?.forEach(a => {
          console.log(a)
          a?.resellerProductOrderDetailResponses?.forEach(detail => {
            excelList.push({

              "Sipariş No": a?.distyOrderNo,
              "Sipariş Sahibi": a?.ownUserNameAndSurname,
              "Sipariş Sahibi Tel": a?.ownUserGsmNo,
              "Onay Durumu": a?.confirmationResellerProductOrder?.orderStatus === "WAITING" ? "Onay Bekliyor" :
                a?.confirmationResellerProductOrder?.orderStatus === "APPROVED" ? "Onaylandı" :
                  a?.confirmationResellerProductOrder?.orderStatus === "REJECTED" ? "Reddedildi" :
                    "İptal Edildi",
              "Sipariş Tarihi": moment(a?.requestDate).format("DD-MM-yyyy HH:mm:ss"),
              "Alt Kategori": detail?.subCategory,
              "Marka": detail?.brandName,
              "Ürün İsmi": detail?.modelName,
              "Barkod": detail?.barcode,
              "İstenen Adet": detail?.requestQuantity,
              "Tutar": detail?.requestQuantity + "x" + detail?.unitPrice + "=" + detail?.requestQuantity * detail?.unitPrice,
              /*"Onaylanan Adet": detail?.approveQuantity ? detail?.approveQuantity : "İnceleme Aşamasında",
              "Onaylanan Tutar": detail?.approvePrice ? detail?.approvePrice : "İnceleme Aşamasında",*/


            })
          })

        });
        const worksheet = xlsx.utils.json_to_sheet(excelList);
        var wscols = [
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
          {width: 22},
        ];
        worksheet["!cols"] = wscols;
        const workbook = {
          Sheets: {'Sipariş Detayları Excel Döküman': worksheet},
          SheetNames: ['Sipariş Detayları Excel Döküman']
        };
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'Sipariş Detayları Excel Döküman');
      })
    }).catch(err => {
      setExportExcelDisabled(false)

    }).finally(() => setExportExcelDisabled(false));


  }


  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>İşlemler</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcel} style={{marginRight: 5}}/>
          <Button type="button" icon="pi pi-external-link" label="Detayları Excel’e Aktar"
                  className="p-button p-button-secondary p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcelDetail}/>
        </div>
      </div>
    </div>;


  return (

    <>
      <Toast ref={toast}/>
      <ResponsiveDataTable
        value={completeResellerOrders}
        loading={loading}
        header={header}
        paginationConfig={paginationConfig}
        onActivePageChange={onActivePageChange}
        emptyMessage="Ürün Bulunamadı."
        onSort={onSort}

        sortField={searchRequest.sortField}
        sortOrder={searchRequest.sortOrder}
      >
        <Column header="Sipariş No" field="distyOrderNo" body={e => {
          return e?.distyOrderNo ? e?.distyOrderNo : e?.id
        }} filter filterElement={distyOrderNoFilter}/>
        <Column header="Sipariş Sahibi" body={e => {
          return (<div>{e?.ownUser?.name + " " + e?.ownUser?.surname}</div>)
        }}
                filter filterElement={hasOrderName}/>
        <Column header="Sipariş Sahibi Tel" field="ownUser.gsmNo" filter filterElement={hasOrderGsmNo}/>
        <Column header="Onay Durumu" body={e => {
          let _orderTypes = [...orderTypes];
          let _type = _orderTypes.find(order => order.key === e?.confirmationResellerProductOrder?.orderStatus)
          return (
            <div>{_type?.value}</div>
          )
        }} filter filterElement={orderStatusFilter} field="confirmationResellerProductOrder.orderStatus"/>

        <Column header="Sipariş Tarihi" body={e => {
          return (<div>{moment(e?.approverDate).format("DD-MM-yyyy HH:mm:ss")} </div>)
        }}/>

        <Column header="Toplam Tutar" body={e => <div>{e.totalPrice + "₺"}</div>} field="totalPrice" sortable/>
        <Column header="İstenen Toplam Adet" field="totalQuantity"/>

        <Column body={actions}/>


      </ResponsiveDataTable>

      <Dialog header="Sipariş Detayı" style={{width: '75%'}} onHide={e => setOrderDetailDialog(false)}
              visible={orderDetailDialog}>

        <p className="marginParagraph"><b>Sipariş No
          :</b> {currentOrder?.distyOrderNo}</p>
        <p className="marginParagraph"><b>Sipariş Sahibi
          :</b> {currentOrder?.ownUserNameAndSurname}</p>
        <p className="marginParagraph"><b>Sipariş Sahibi GsmNo
          :</b> {currentOrder?.ownUserGsmNo}</p>
        <p className="marginParagraph"><b>Sipariş Onay Durumu
          :</b> {orderTypes.find(order => order.key === currentOrder?.orderStatus)?.value}</p>
        <p className="marginParagraph"><b>Sipariş Tarihi
          :</b> {moment(currentOrder?.approverDate).format("DD-mm-yyyy HH:MM:ss")}</p>
        <p className="marginParagraph"><b>İstenen Toplam Adet
          :</b> {currentOrder?.requestTotalQuantity}</p>
        <p className="marginParagraph"><b>Toplam Tutar
          :</b> {currentOrder?.requestTotalPrice}₺</p>



        <ResponsiveDataTable
          value={currentOrder?.resellerProductOrderDetailResponses}
        >
          <Column header="Alt Kategori" field="subCategory"/>
          <Column header="Marka" field="brandName"/>
          <Column header="Ürün İsmi" field="modelName"/>
          <Column header="Barkod" field="barcode"/>
          <Column header="İstenen Adet" field="requestQuantity"/>
          <Column header="Tutar" field="requestPrice" body={e => {
            return (<div>{e?.requestQuantity + "x" + e?.unitPrice + "₺=" + e.requestPrice}₺</div>)
          }}/>


        </ResponsiveDataTable>


        {isReseller ?
          <Button label="Siparişi İptal Et" disabled={currentOrder?.orderStatus !== 'WAITING' || !orderDetailDialog}
                  onClick={e => {
                    resellerOrderService.cancelResellerProductOrderById(currentOrder?.resellerProductOrderId).then(res => {

                      if (res) {
                        toast?.current?.show({
                          severity: 'success',
                          summary: 'Başarılı İşlem',
                          detail: res,
                          life: 3000,
                        });

                      } else {
                        toast?.current?.show({
                          severity: 'info',
                          detail: 'Sipariş İptali için geç kalınmıştır',
                          life: 3000,
                        });
                      }
                      setOrderDetailDialog(false);

                      getAll({...paginationConfig})


                    }).catch(err => handleError(err, toast))

                  }}/> : <div></div>}

      </Dialog>

    </>
  )

}
export default ResellerOrderView;
