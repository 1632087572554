import AspAxios from "../../service/AspAxios";
import * as Paths from '../../service/config'

export default class VoucherService {

  searchSaleInfo(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchSaleInfo + '?size=' + _size + '&page=' + _page  + '&sort=' + _sort, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  searchSaleInfoEager(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchSaleInfoEager, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  searchProductSale(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchProductSale + '?size=' + _size + '&page=' + _page  + '&sort=' + _sort, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  searchProductSaleEager(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchProductSaleEager, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  cancelTheCompletedSale(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.cancelTheCompletedSale, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  cancelSaleApprove(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.cancelSaleApprove, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  multiCancelSaleApprove(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.multiCancelSaleApprove, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  cancelSaleReject(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.cancelSaleReject, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerSerialTransfer(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerSerialTransfer, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  mySerialTransfers(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.mySerialTransfers + '?size=' + _size + '&page=' + _page  + '&sort=' + _sort, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  mySerialTransfersEager(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.mySerialTransfersEager, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerSerialTransferApprove(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerSerialTransferApprove, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerSerialTransferReject(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerSerialTransferReject, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  searchSupplyInvoiceLazy(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchSupplyInvoiceLazy + '?size=' + _size + '&page=' + _page  + '&sort=' + _sort, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  searchSupplyInvoiceEager(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchSupplyInvoiceEager, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  findSupplyInvoiceDetailByInvoiceId(invoiceId) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.findSupplyInvoiceDetailByInvoiceId + invoiceId).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

};
