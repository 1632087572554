import {Dialog} from "primereact/dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Column} from "primereact/column";
import {handleError} from "../../service/HandleErrorService";
import {Button} from "primereact/button";
import ProductService from "./httpService";
import {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {FileUpload} from "primereact/fileupload";
import moment from "moment";

const ProductExcel = () => {
  let loadLazyTimeout = null;
  const [loading, setLoading] = useState(false);
  const productService = new ProductService();
  const [importedData, setImportedData] = useState([]);
  const [selectedImportedData, setSelectedImportedData] = useState([]);
  const [importedCols, setImportedCols] = useState([{field: '', header: 'Header'}]);
  const [excelDialogOpen, setExcelDialogOpen] = useState(false);
  const [excelFailData, setExcelFailData] = useState([]);
  const [checkData, setCheckData] = useState([]);
  const toast = useRef(null);
  const [checkSupply, setCheckSupply] = useState(false);
  const [checkDisty, setCheckDisty] = useState(false);
  const [picture, setPicture] = useState();
  const [count, setCount] = useState();
  const [checkClear, setCheckClear] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const exampleModelPrice = [
    {
      "Kategori": "Kablolu Kulaklıklar",
      "Alt Kategori": "Kablolu Kulaklıklar",
      "Marka": "TTEC",
      "Ürün İsmi": "2KM111K ttec EchoPro Kumandalı ve Mikrofonlu Kulakiçi Kulaklık  Kırmızı",
      "Barkod": 8694470646961,
      "Ürün Açıklaması": "2KM111K ttec EchoPro Kumandalı ve Mikrofonlu Kulakiçi Kulaklık  Kırmızı",
      "Ürün Fiyatlarının Geçerli Olacağı Tarih": 20211030,
      "EOL Var mı": "HAYIR",
      "Menşei Ülke": "Turkiye",
      "Tedarik Durumu": "AÇIK",
      "Distribütör Alış Fiyatı": 45.50,
      "Önerilen EDM Alış Fiyatı": 48,
      "Önerilen Bayi Alış Fiyatı": 50,
      "Önerilen Satış Fiyatı": 55.00,
      "Kdv Oranı": 18,
      "Resim1": "https://st3.myideasoft.com/idea/bi/08/myassets/products/229/2km111-ttec-echopro-kumandali-mikrofonlu-kulakici-kulaklik-kirmizi.png?revision=1537527728",
      "Resim2": "https://st2.myideasoft.com/idea/bi/08/myassets/products/229/2km111k-echopro.jpg?revision=1529566970",
      "Resim3": "https://st1.myideasoft.com/idea/bi/08/myassets/products/229/mstf8714-1-echopro-kirmizi.png?revision=1605682838",
      "Resim4": "https://st1.myideasoft.com/idea/bi/08/myassets/products/229/mstf8714-1-echopro-kirmizi.png?revision=1605682838",
      "Resim5": "https://st1.myideasoft.com/idea/bi/08/myassets/products/229/mstf8714-1-echopro-kirmizi.png?revision=1605682838",
      "Resim6": "https://st1.myideasoft.com/idea/bi/08/myassets/products/229/mstf8714-1-echopro-kirmizi.png?revision=1605682838",
    }


  ];

  useEffect(() => {
    setCheckSupply(JSON.parse(localStorage.getItem('user'))?.supply !== undefined);
    setCheckDisty(JSON.parse(localStorage.getItem('user'))?.disty !== undefined);
  }, []);

  const exportExcel = (e) => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(exampleModelPrice);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Ürünler İçin Örnek Excel Dosyası');
    });
  }


  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }

  const importExcel = ({files}) => {
    if (files.length === 1) {
      const file = files[0];
      let formData = new FormData();
      formData.append('file', file);
      productService.savePriceExcel(formData);
      files.length = 0;
      import('xlsx').then(xlsx => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const wb = xlsx.read(e.target.result, {type: 'array'});
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = xlsx.utils.sheet_to_json(ws, {header: 1});

          // Prepare DataTable
          const cols = data[0];
          data.shift();


          let _importedCols = cols?.map(col => ({field: col, header: toCapitalize(col)}));
          let _importedData = data?.map(d => {
            return cols.reduce((obj, c, i) => {
              obj[c] = d[i];
              return obj;
            }, {});
          });


          let excelProductPrices = (_importedData.map(value => {
            if (value['Barkod'] !== undefined && value['Barkod'] !== null) {
              value['Barkod'] = value['Barkod'].toString()

            }
            if (value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] !== undefined) {

              value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] = value['Ürün Fiyatlarının Geçerli Olacağı Tarih'].toString();
              const _year = value['Ürün Fiyatlarının Geçerli Olacağı Tarih'].substring(0, 4);
              const _mont = value['Ürün Fiyatlarının Geçerli Olacağı Tarih'].substring(4, 6);
              const _day = value['Ürün Fiyatlarının Geçerli Olacağı Tarih'].substring(6, 8);
              const _hour = "16";
              const _min = "00";
              const _second = "00";

              value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] = new Date(_year, (_mont - 1), _day, _hour, _min, _second);
              value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] = moment(value['Ürün Fiyatlarının Geçerli Olacağı Tarih']).format();

            }
            return {
              pictures: [value['Resim1'] ? value['Resim1'] : undefined, value['Resim2'] ? value['Resim2'] : undefined, value['Resim3'] ? value['Resim3'] : undefined
                , value['Resim4'] ? value['Resim4'] : undefined, value['Resim5'] ? value['Resim5'] : undefined, value['Resim6'] ? value['Resim6'] : undefined],
              ...value
            }
          }));

          let _excelProductPrices = (excelProductPrices.map(value => {
            return {
              categoryName: value['Kategori'] ? value['Kategori'] : null,
              subCategoryName: value['Alt Kategori'] ? value['Alt Kategori'] : null,
              brandName: value['Marka'] ? value['Marka'] : null,
              modelName: value['Ürün İsmi'] ? value['Ürün İsmi'] : null,
              barcode: value['Barkod'] ? value['Barkod'] : null,
              description: value['Ürün Açıklaması'] ? value['Ürün Açıklaması'] : null,
              startDate: value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] ? value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] : null,
              eol: value['EOL Var mı'] ? value['EOL Var mı'] : null,
              supplyStatus: value['Tedarik Durumu'] ? value['Tedarik Durumu'] : null,
              origin: value['Menşei Ülke'] ? value['Menşei Ülke'] : null,
              distyPrice: value['Distribütör Alış Fiyatı'] ? value['Distribütör Alış Fiyatı'] : null,
              recommendedEdmPrice: value['Önerilen EDM Alış Fiyatı'] ? value['Önerilen EDM Alış Fiyatı'] : null,
              recommendedResellerPrice: value['Önerilen Bayi Alış Fiyatı'] ? value['Önerilen Bayi Alış Fiyatı'] : null,
              recommendedSellPrice: value['Önerilen Satış Fiyatı'] ? value['Önerilen Satış Fiyatı'] : null,
              taxRatio: value['Kdv Oranı'] ? value['Kdv Oranı'] : null,
              pictures: value['pictures'] ? value['pictures'] : null,


            }
          }));


          await productService.readExcel(_excelProductPrices).then(res => {
            setCheckClear(true)

            toast.current?.show({
              severity: "info",
              detail: 'Tablodan Ürünleri kontrol edebilirsiniz',
              life: 1000,
            });


            setCheckData(res?.checkData);
            if (res?.checkData) {
              setCount(1);
            }
            setExcelFailData(res?.errorData)

            setExcelDialogOpen(true);
          }).catch(err => handleError(err, toast))
          setImportedCols(_importedCols);
          setImportedData(_importedData);
        };

        reader.readAsArrayBuffer(file);
      });
    }
  };

  const clear = () => {
    setImportedData([]);
    setExcelFailData([]);
    setCheckData([]);
    setSelectedImportedData([]);
    setCheckClear(false)
    setImportedCols([{field: '', header: 'Header'}]);
  }
  const toCapitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  const showExcelDialog = () => {
    setExcelDialogOpen(true);

  }
  const hideExcelDialog = () => {
    setExcelDialogOpen(false);


  }
  const excelDialogFooter = (
    <>
      <Button label="Vazgeç" onClick={hideExcelDialog} icon="pi pi-times" className="p-button-text"/>
      <Button label="Onaylanan Ürünleri Kaydet" onClick={e => excelDataUpsert(e)} icon="pi pi-check"/>
    </>
  );

  const excelDataUpsert = (e) => {



      setLoading(true);

      if (loadLazyTimeout) {
        clearTimeout(loadLazyTimeout);
      }


    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      productService.saveExcel(checkData).then(res => {

        if (res) {
          toast?.current?.show({
            severity: "success",
            detail: 'Ürün ekleme talebiniz onaya gönderildi',
            life: 3000,
          });
        } else {
          toast?.current?.show({
            severity: "warn",
            detail: 'Onaylanan Ürün Bulunamadı',
            life: 3000,
          });
        }
        setExcelDialogOpen(false);
        setCheckData([]);
        setExcelFailData([])
        setImportedCols(null)
        setCheckClear(false);

        setLoading(false);
      }).catch(err => handleError(err, toast));
    },Math.random() * 1000 + 250);
    }

  const errorTempate = (e, name, event) => {


    let boolean = e?.errorMaps !== undefined && e.errorMaps[name] === true;
    if (boolean) {
      return <div style={{backgroundColor: 'pink'}}>{e[name] ? e[name] : "Boş Değer"}</div>
    } else {

      if (name === "distyPrice" || name === "recommendedResellerPrice" || name === "recommendedSellPrice") {
        return <div>{e[name]}₺</div>
      } else if (name === "taxRatio") {
        return <div>%{e[name]}</div>
      } else if (name === "startDate") {
        return <div>{moment(e.startDate).format("DD-MM-YYYY")}</div>

      }

      return <div>{e[name]}</div>

    }

  }

  const priceTemplate = (e, name) => {
    return <div>{e[name]}₺</div>
  }

  const kdvTemplate = (e) => {
    return <div>%{e.taxRatio}</div>
  }

  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  }
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  }

  return (
    <>

      <Toast ref={toast}/>
      <div className="p-d-flex p-ai-center p-py-2">


        <Button label="Örnek Excel doküman indir" onClick={e => exportExcel(e)} className="p-button-help"/>
        <FileUpload
          maxFileSize={100000000}
          chooseOptions={{label: 'Excel Dokümanı Yükle', icon: 'pi pi-file-excel', className: 'p-button-success'}}
          mode="basic" name="demo[]" auto
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          className="p-mr-2" customUpload={true} uploadHandler={importExcel}/>

        {checkClear ? <Button type="button" style={{marginRight: 10}}
                              label="Yüklenen Verileri Temizle" icon="pi pi-times"
                              onClick={clear} className="p-button-info"/> : ""}
      </div>


      <TabView>
        <TabPanel  header="Onaylanan Ürünler">
          <div className="datatable-responsive-demo">
            <div className="p-col-2" style={{
              backgroundColor: 'aliceblue',
              fontSize: 8,
              marginBottom: 25
            }}>{checkData?.length > 0 ? `${checkData.length} tane doğru kayıt bulundu` : "doğru kayıt bulunamadı"}</div>

            <ResponsiveDataTable value={checkData}
                                 paginator rows={20}
                                 emptyMessage="Ürün Bulunamadı.">
              <Column style={{fontSize: 8}} field="number" header="Numara"/>
              <Column style={{fontSize: 8}} field="categoryName" header="Kategori"/>
              <Column style={{fontSize: 8}} field="subCategoryName" header="Alt Kategori"/>
              <Column style={{fontSize: 8}} field="brandName" header="Marka"/>
              <Column style={{fontSize: 8}} field="modelName" header="Ürün İsmi"/>
              <Column style={{fontSize: 8}} field="barcode" header="Barkod"/>
              <Column style={{fontSize: 8}} field="description" header="Ürün Açıklaması"/>
              <Column style={{fontSize: 8}} field="origin" header="Menşei Ülke"/>
              <Column style={{fontSize: 8}} field="supplyStatus" header="Tedarik Durumu"/>
              <Column style={{fontSize: 8}} body={e => moment(e.startDate).format("DD:MM:YYYY")}
                      header="Ürün Fiyatlarının Geçerli Olacağı Tarih"/>
              <Column style={{fontSize: 8}} field="eol" header="Eol Var mı"/>
              <Column style={{fontSize: 8}} body={e => priceTemplate(e, "distyPrice")}
                      header="Distribütör Alış Fiyatı"/>
              <Column style={{fontSize: 8}} body={e => priceTemplate(e, 'recommendedEdmPrice')} header="Önerilen EDM Alış Fiyatı"/>
              <Column style={{fontSize: 8}} body={e => priceTemplate(e, "recommendedResellerPrice")}
                      header="Önerilen Bayi Alış Fiyatı"/>
              <Column style={{fontSize: 8}} body={e => priceTemplate(e, "recommendedSellPrice")}
                      header="Önerilen Satış Fiyatı"/>
              <Column style={{fontSize: 8}} body={e => kdvTemplate(e)} header="KDV Oranı"/>


            </ResponsiveDataTable>
          </div>
          <Button label="Onaylanan Ürünleri Kaydet" onClick={e => excelDataUpsert(e)} icon="pi pi-check"/>


        </TabPanel>
        <TabPanel header="Hatalı Olan Ürünler">
          <div className="datatable-responsive-demo">
            <div className="p-col-2" style={{
              backgroundColor: 'aliceblue',
              fontSize: 8,
              marginBottom: 25
            }}>{excelFailData?.length > 0 ? `${excelFailData.length} tane hatalı kayıt bulundu` : "hatalı kayıt bulunamadı"}</div>
            <ResponsiveDataTable value={excelFailData}


                                 paginator rows={20}
                                 emptyMessage="Ürün Bulunamadı.">
              <Column  style={{fontSize: 8}} field="number" header="Numara"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'categoryName')} header="Kategori"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'subCategoryName')} header="Alt Kategori"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'brandName')} header="Marka"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'modelName')} header="Ürün ismi"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'barcode')} header="Barkod"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'description')} header="Ürün Açıklaması"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'origin')} header="Menşei Ülke"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'supplyStatus')} header="Tedarik Durumu"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'startDate')}
                      header="Ürün Fiyatlarının Geçerli Olacağı Tarih"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'eol')} header="Eol Var mı"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'distyPrice')} header="Distribütör Alış Fiyatı"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'recommendedEdmPrice')} header="Önerilen EDM Alış Fiyatı"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'recommendedResellerPrice')}
                      header="Önerilen Bayi Alış Fiyatı"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'recommendedSellPrice')}
                      header="Önerilen Satış Fiyatı"/>
              <Column style={{fontSize: 8}} body={e => errorTempate(e, 'taxRatio')} header="KDV Oranı"/>
            </ResponsiveDataTable>
          </div>
        </TabPanel>


      </TabView>


      <br/>
      <br/>
      <br/>
      <br/>
      <br/>


    </>
  )
}
export default ProductExcel
